const checkMobileOrientation = function(imgIcon) {
  function isLandscape() {
    //return window.matchMedia("(orientation: landscape)").matches
    return Math.abs(window.orientation) === 90
  }

  function isPortrait() {
    //return window.matchMedia("(orientation: portrait)").matches
    return Math.abs(window.orientation) !== 90
  }

  function checkOrientation() {
    let siteBody = document.querySelector("body")

    let message = "Please use mobile on portrait for a better experience"
    let orientationMsgWrapper = document.createElement("div")
    orientationMsgWrapper.setAttribute("id", "MsgWrap")
    orientationMsgWrapper.setAttribute("class", "message_Wrap")

    let orientationMsgChild = document.createElement("div")
    orientationMsgChild.setAttribute("class", "message_Inner")
    orientationMsgWrapper.appendChild(orientationMsgChild)

    let msgImg = document.createElement("img")
    msgImg.setAttribute("src", imgIcon)
    msgImg.setAttribute("class", "message_Icon")
    msgImg.setAttribute("width", "100")
    msgImg.setAttribute("alt", "rotate your phone")
    orientationMsgChild.appendChild(msgImg)

    let orientationMsg = document.createElement("span")
    orientationMsg.setAttribute("class", "message_Text")
    orientationMsg.innerHTML = message
    orientationMsgChild.appendChild(orientationMsg)
    let orientationDiv = document.getElementById("MsgWrap")

    function removeChild() {
      document.body.removeChild(orientationDiv)
    }

    if (isLandscape()) {
      siteBody.classList.add("no-scroll")
      document.body.appendChild(orientationMsgWrapper)
    }

    if (isPortrait()) {
      if (orientationDiv !== null) {
        orientationDiv.classList.add("message_Out")
        siteBody.classList.remove("no-scroll")
        setTimeout(removeChild, 850)
      }
    }
  }

  setTimeout(checkOrientation, 0)

  window.addEventListener(
    "orientationchange",
    function() {
      checkOrientation()
    },
    false
  )
}

module.exports = checkMobileOrientation
