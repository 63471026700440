//scss for all pages
import "./src/utils/scss/index.scss"
import { hideNavMenuOnScrollDown } from "./src/modules/hideRevealFixedBottomNavMenu"
import updateNavbarColor from "./src/modules/updateNavbarColor"
import rotateIcon from "./content/assets/vz-rotate-screen.svg"
import checkMobileOrientation from "./src/modules/checkDeviceOrientation"
import { isMobile } from "./src/modules/checkIfMobile"
//import lazyLoadVideos from "./src/modules/lazyLoadVideos"
import LazyLoad from "vanilla-lazyload"
import movingEyeLogo from "./src/modules/movingEyeLogo"

function vzScript() {
  function cursorMouseStates(selector, mouseEvent, scrollText) {
    selector.addEventListener(mouseEvent, function() {
      const ctmCursorTxt = document.getElementById("cursorTxt")
      if (typeof ctmCursorTxt != "undefined" && ctmCursorTxt != null) {
        ctmCursorTxt.innerHTML = scrollText
      }
    })
  }

  function emptyCursorCircle(elem, circleTextOver, circleTextLeave) {
    cursorMouseStates(elem, "mouseenter", circleTextOver)
    cursorMouseStates(elem, "mouseleave", circleTextLeave)
  }

  const workCardsArray = document.querySelectorAll(".post-card-link")
  workCardsArray.forEach((workCard, index) => {
    emptyCursorCircle(workCard, "View", "Scroll")
    updateNavbarColor(".section-works")
  })

  const siteHeader = document.getElementById("siteHeader")
  emptyCursorCircle(siteHeader, "", "Scroll")

  const linksArray = document.querySelectorAll(".link")
  linksArray.forEach((link, index) => {
    emptyCursorCircle(link, "", "Scroll")
  })

  const pageTitle = document.getElementById("pageTitle")
  if (typeof pageTitle != "undefined" && pageTitle != null) {
    // exists.
    emptyCursorCircle(pageTitle, "", "Scroll")
  }
}
/*if (process.env.NODE_ENV !== `production`) {

}*/
export const onRouteUpdate = ({ location }, pluginOptions = {}) => {
  if (typeof window !== "undefined") {
    setTimeout(vzScript, 1100)
    hideNavMenuOnScrollDown("bottomNav")

    new LazyLoad({
      elements_selector: "video.lazy",
    })

    if (isMobile.any() && !navigator.userAgent.match(/iPad/i)) {
      setTimeout(function() {
        checkMobileOrientation(rotateIcon)
      }, 100)
    }

    if (!isMobile.any()) {
      movingEyeLogo("eye_iris", 500)
    }

    setTimeout(function() {
      const htmlBody = document.querySelector("html")
      htmlBody.classList.add("lightBg")
    }, 2500)
  }
}
