const hideNavMenuOnScrollDown = function(elementId) {
  //const body = document.body;
  const bottomNavWrap = document.getElementById(elementId)
  const scrollUp = "scrolling-up"
  const scrollDown = "scrolling-down"
  let lastScroll = 0

  window.addEventListener("scroll", () => {
    const currentScroll = window.pageYOffset
    if (currentScroll <= 0) {
      bottomNavWrap.classList.remove(scrollUp)
      return
    }

    if (
      currentScroll > lastScroll &&
      !bottomNavWrap.classList.contains(scrollDown)
    ) {
      // down
      bottomNavWrap.classList.remove(scrollUp)
      bottomNavWrap.classList.add(scrollDown)
    } else if (
      currentScroll < lastScroll &&
      bottomNavWrap.classList.contains(scrollDown)
    ) {
      // up
      bottomNavWrap.classList.remove(scrollDown)
      bottomNavWrap.classList.add(scrollUp)
    }
    lastScroll = currentScroll
  })
}

module.exports = {
  hideNavMenuOnScrollDown: hideNavMenuOnScrollDown,
}
