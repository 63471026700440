const updateNavbarColor = function(elemClass) {
  function getHexcolor(hexcolor) {
    if (hexcolor.slice(0, 1) === "#") {
      hexcolor = hexcolor.slice(1)
    }

    if (hexcolor.length === 3) {
      hexcolor = hexcolor
        .split("")
        .map(function(hex) {
          return hex + hex
        })
        .join("")
    }

    let r = parseInt(hexcolor.substr(0, 2), 16)
    let g = parseInt(hexcolor.substr(2, 2), 16)
    let b = parseInt(hexcolor.substr(4, 2), 16)

    let yiq = (r * 299 + g * 587 + b * 114) / 1000

    return yiq >= 128 ? "dark" : "light"
  }

  function parseColor(color) {
    let arr = []
    color.replace(/[\d+.]+/g, function(v) {
      arr.push(parseFloat(v))
    })
    return (
      "#" +
      arr
        .slice(0, 3)
        .map(toHex)
        .join("")
    )
  }

  function toHex(int) {
    let hex = int.toString(16)
    return hex.length === 1 ? "0" + hex : hex
  }

  if (typeof elemClass != "undefined" && elemClass != null) {
    function elementOnTopOfViewport(elemClass) {
      let nav = document.getElementById("siteHeader")
      const workSections = document.querySelectorAll(elemClass)
      const bodyTag = document.getElementById("body")
      let windowPageYOffset = window.pageYOffset
      let navHeight = nav.clientHeight
      let calculateTop //based in class work existing in body
      if (bodyTag.classList.contains("work"))
        calculateTop = windowPageYOffset + navHeight
      else calculateTop = windowPageYOffset + navHeight / 2
      let windowBelowNav = calculateTop
      let scrollIsInATarget = false
      let dataBgIsLight = false

      workSections.forEach(function(workSection, i) {
        let target = workSection
        let targetHeight = target.clientHeight
        let targetTopOffset = target.offsetTop
        let targetBottomOffset = targetTopOffset + targetHeight
        let bgColor = target.style.backgroundColor
        let newColor = parseColor(bgColor)

        if (windowBelowNav >= targetTopOffset) {
          if (windowPageYOffset <= targetBottomOffset) {
            scrollIsInATarget = true
            if (getHexcolor(newColor) === "light") {
              dataBgIsLight = true
            }
          }
        }
      })

      if (scrollIsInATarget) {
        if (dataBgIsLight) {
          nav.classList.add("lighter")
        }
      } else {
        nav.classList.remove("lighter")
      }
    }

    window.onscroll = function() {
      elementOnTopOfViewport(elemClass)
    }
  }
}

module.exports = updateNavbarColor
