function movingEyeLogo(eyeIris, timeout) {
  let $eyeIris = document.getElementById(eyeIris)

  setTimeout(function() {
    if ($eyeIris) {
      window.addEventListener("mousemove", evt => {
        const x = -(window.innerWidth / 2 - evt.pageX) / 120
        const y = -(window.innerHeight / 2 - evt.pageY) / 660
        const yCalc = window.innerHeight > 5000 ? 0 : 1.5
        $eyeIris.style.transform = `translateY(${y /
          yCalc}px) translateX(${x}px)`
      })
    }
  }, timeout)
}

module.exports = movingEyeLogo
